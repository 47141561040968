import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Table, Button, Pagination } from "react-bootstrap";
import ModalCreateExpertise from './modalCreateExpertise';
import moment from 'moment';
import axiosInstance from '../../axiosInstance'; 



function ListExpertise() {
  const navigate = useNavigate();
  const [expertiseData, setExpertiseData] = useState({ results: [], count: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Définir selon vos besoins
  const [searchTerm, setSearchTerm] = useState('');


  const handleExpertiseCreated = (newExpertise) => {
    setExpertiseData(prevData => ({
        ...prevData,
        results: [...prevData.results, newExpertise],
        count: prevData.count + 1
    }));
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/expertise/?page=${currentPage}&search=${encodeURIComponent(searchTerm)}`);
        const data = response.data;
        // Assuming the API returns an object with 'results' as the array of items and 'count' as the total number of items
        setExpertiseData({ results: data.results, count: data.count });
      } catch (error) {
        console.error("Il y a eu un problème avec la requête", error);
      }
    };
  
    fetchData();
  }, [currentPage, searchTerm]); // Ajouter searchTerm comme dépendance
  
  

  const handleEditClick = (expertiseId) => {
    navigate(`/edit-expertise/${expertiseId}`);
  };
  

  const totalPages = Math.ceil(expertiseData.count / itemsPerPage);
  
  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber); // Mettre à jour l'état currentPage
    axiosInstance.get(`/expertise/?page=${pageNumber}`)
      .then(response => {
        console.log(expertiseData.count)
        setExpertiseData(response.data);

      })
      .catch(error => {
        console.error("Il y a eu un problème avec la requête", error);
      });
  };
  
  

  return (
    <Container fluid>
      <Row>
        <Col md={4} style={{ marginBottom: "10px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
        <ModalCreateExpertise onExpertiseCreated={handleExpertiseCreated} onEditClick={handleEditClick} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Statut</th>
                <th>Expertise</th>
                <th>Référence</th>
                <th>Créé le</th>
                <th>Modifié le</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {expertiseData.results.map((expertise) => (
                <tr key={expertise.id}>
                  <td>{expertise.statut}</td>
                  <td>{expertise.name}</td>
                  <td>{expertise.reference}</td>
                  <td>{moment(expertise.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{moment(expertise.modifiedAt).format('DD/MM/YYYY')}</td>
                  <td><Button variant="primary" onClick={() => handleEditClick(expertise.id)}>Modifier</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination>
            {[...Array(totalPages)].map((_, i) => (
              <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handleChangePage(i + 1)}>
                {i + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
}

export default ListExpertise;
