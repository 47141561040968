import React, { useState } from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import Logs from '../../components/Logs';
import TaskList from '../../components/Tasks/taskList';

function Home() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <Logs />
        </Col>
      </Row>
      <Row>
        <Col>
          <TaskList />
        </Col>
      </Row>
    </Container>
  )
}

export default Home