import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ListOrganizationFile from './listOrganizationFile';

function OrganizationFiles() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListOrganizationFile />
        </Col>
      </Row>
    </Container>
  );
}

export default OrganizationFiles;