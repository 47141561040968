import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/';
import Home from './pages/Home/';
import Expertise from './pages/Expertise/';
import ManagerExpertise from './pages/ManagerExpertise';
import ManagerOrganization from './pages/ManagerOrganization';
import Error from './components/Error/';
import Login from './pages/Login/';
import Logout from './pages/Logout/';
import ActivateAccount from './pages/ActivateAccount';
import { Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from "react-bootstrap";
import Signup from './pages/Signup';
import './index.css';


function ProtectedRoute({ children }) {
  const access_token = localStorage.getItem('access_token');
  const isLoggedIn = access_token;
  const location = useLocation();


  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const App = () => {
  const access_token = localStorage.getItem('access_token');
  const isLog = access_token? true:false;
  const [isLoggedIn, setIsLoggedIn] = useState(isLog);

  return (
    <React.StrictMode>
      <Router>
        <Row className='fixed-top'>
          <Col>
            <Header isLoggedIn={isLoggedIn} />
          </Col>
        </Row>
        <Row style={{ paddingTop: '30px' }}>
          <Col>
            <Routes>
              <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/expertise/" element={<ProtectedRoute><Expertise /></ProtectedRoute>} />
              <Route path="/edit-expertise/:expertiseId" element={<ProtectedRoute><ManagerExpertise /></ProtectedRoute>} />
              <Route path="/manager/" element={<ProtectedRoute><ManagerOrganization /></ProtectedRoute>} />
              <Route path="/logout" element={<ProtectedRoute><Logout setIsLoggedIn={setIsLoggedIn} /></ProtectedRoute>} />
              <Route path="*" element={<Error />} />
              <Route path="/activate/:uidb64/:token/" component={ActivateAccount} />
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </Col>
        </Row>
      </Router>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
