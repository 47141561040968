import React, { useState } from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import './index.css'


import EditExpertise from '../../components/Expmanage/editExpertise';
import EditContract from '../../components/Contract/editContract';
import EditClaims from '../../components/Claims/editClaims';
import Report from '../../components/Report';
import Contents from '../../components/Contents';
import Costings from '../../components/Costings';
import Files from '../../components/Files';
import Contact from '../../components/Contact';
import Tasks from '../../components/Tasks';


function ManagerExpertise() {
    const [activeKey, setActiveKey] = useState('EditExpertise');

    return (
        <Container fluid>
            <Row style={{ paddingTop: '56px' }}>
                <Col md={2} className="bg-light border-right fixed-nav" >
                    <Nav className="flex-column mt-2" variant="pills" activeKey={activeKey} onSelect={setActiveKey}>
                        <Nav.Item>
                            <Nav.Link eventKey="EditExpertise"><img src="/contrat.png" alt="Expertise" style={{ width: '32px', height: '32px' }} /> Expertise</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Tasks"><img src="/suivi.png" alt="Suivi" style={{ width: '32px', height: '32px' }} /> Suivi</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="EditContract"><img src="/contrat.png" alt="Contrat" style={{ width: '32px', height: '32px' }} /> Contrat</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="EditClaims"><img src="/sinistre.png" alt="Sinistre" style={{ width: '32px', height: '32px' }} /> Sinistre</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Contact"><img src="/gens.png" alt="Contact" style={{ width: '32px', height: '32px' }} /> Personnes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Report"><img src="/rapport.png" alt="Rapport" style={{ width: '32px', height: '32px' }} /> Rapport</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Costings"><img src="/calculatrice.png" alt="Calculatrice" style={{ width: '32px', height: '32px' }} /> Bâtiment</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Contents"><img src="/calculatrice.png" alt="Calculatrice" style={{ width: '32px', height: '32px' }} /> Contenu</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Files"><img src="/camera.png" alt="Camera" style={{ width: '32px', height: '32px' }} /> Documents</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col md={10} style={{ marginLeft: '16.66666667%' }}>
                    {activeKey === 'EditExpertise' && <EditExpertise />}
                    {activeKey === 'Tasks' && <Tasks />}
                    {activeKey === 'EditContract' && <EditContract />}
                    {activeKey === 'EditClaims' && <EditClaims />}
                    {activeKey === 'Contact' && <Contact />}
                    {activeKey === 'Report' && <Report />}
                    {activeKey === 'Costings' && <Costings />}
                    {activeKey === 'Contents' && <Contents />}
                    {activeKey === 'Files' && <Files />}
                </Col>
            </Row>
        </Container>
    );
}

export default ManagerExpertise;
