import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; 
import { Link } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';

function RequestLogs() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        fetchLogs();
    }, []);
    const fetchLogs = async () => {
        try {
            const response = await axiosInstance.get('/requestlog/');
            const data = response.data;
            setLogs(data.results || data);
        } catch (error) {
            console.error('Error fetching logs', error);
        }
    };
    return (
        <Container fluid>
            <h2 className="expertise-title">Dernières expertises consultées</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Dernières consultations</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map(log => (
                        <tr key={log.id}>
                            <td>
                            <Link to={`/edit-expertise/${log.id}`} className="btn btn-outline-primary">{log.path}</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default RequestLogs;
