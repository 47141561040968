import React, { useState, useCallback } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import TaskModelView from './taskModelView';

function TaskModel() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <TaskModelView />
        </Col>
      </Row>
    </Container>
  );
}

export default TaskModel;