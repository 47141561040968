import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import expomisLogo from '../../assets/expomis_logo.png';

 
function Header({ isLoggedIn }) {
  console.log('log', isLoggedIn)
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand>
          <img
            alt="Logo"
            src={expomisLogo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Iziclai
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
          {isLoggedIn ? (
            <>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/expertise/">Expertise</Nav.Link>
              <Nav.Link as={Link} to="/manager/">Gestionnaire</Nav.Link>
              <Nav.Link as={Link} to="/logout/">Logout</Nav.Link>
            </>
            ) : (
              <>
                <Nav.Link as={Link} to="/login/">Se connecter</Nav.Link>
                <Nav.Link as={Link} to="/signup/">S'inscrire</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header