import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

function InfoPopover() {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h2">Données pouvant être utilisées</Popover.Header>
            <Popover.Body>
                <div>
                    <h3>Données générales</h3>
                    <ul>
                        <li>reference_interne</li>
                        <li>expertise_nom</li>
                        <li>claim_reference_cie</li>
                        <li>claim_garantees</li>
                        <li>claim_date</li>
                        <li>claim_adress</li>
                        <li>insurance_contract_reference</li>
                        <li>insurance_contract_cie</li>
                    </ul>
                    <h3>Données sur les images</h3>
                    <ul>
                        <li>photos = []</li>
                        <li>photo["title"]</li>
                        <li>photo["description"]</li>
                        <li>photo["category"]</li>
                        <li>photo["groupe"]</li>
                        <li>photo["file"]</li>
                    </ul>
                    <h3>Données sur les personnes</h3>
                    <ul>
                        <li>personnes = []</li>
                        <li>personne["genre"]</li>
                        <li>personne["name"]</li>
                        <li>personne["firstname"]</li>
                        <li>personne["mail"]</li>
                        <li>personne["phone"]</li>
                        <li>personne["role"]</li>
                        <li>personne["reference"]</li>
                        <li>personne["letterbox"]</li>
                        <li>personne["number"]</li>
                        <li>personne["street"]</li>
                        <li>personne["city"]</li>
                        <li>personne["postcode"]</li>
                        <li>personne["country"]</li>
                    </ul>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popover}>
            <Button variant="info" size="sm">Voir les informations</Button>
        </OverlayTrigger>
    );
}

export default InfoPopover;
