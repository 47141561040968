import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { Container, Button, Table, InputGroup, FormControl, Form, Pagination, Row, Col } from 'react-bootstrap';


function TaskList() {
    const navigate = useNavigate();
    const { expertiseId } = useParams();
    const [tasks, setTasks] = useState([]);
    const [error, setError] = useState('');
    
    // États pour les critères de recherche
    const [searchStatus, setSearchStatus] = useState('start'); // Option par défaut "start"
    const [searchTitle, setSearchTitle] = useState('');
    const [searchReceiver, setSearchReceiver] = useState('');
    const [searchDueDate, setSearchDueDate] = useState('');

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        fetchTasks();
    }, [expertiseId, currentPage, searchStatus, searchTitle, searchReceiver, searchDueDate]);

    const fetchTasks = async () => {
        try {
            const response = await axiosInstance.get('/tasks/', {
                params: {
                    status: searchStatus || undefined,
                    title: searchTitle || undefined,
                    receiver: searchReceiver || undefined,
                    review_at: searchDueDate || undefined,
                    page: currentPage,
                    page_size: itemsPerPage
                }
            });
            setTasks(response.data.results || []);
            setTotalItems(response.data.count);
            setError('');
        } catch (err) {
            setError('Erreur lors de la récupération des tâches');
            console.error('Fetch tasks error:', err);
        }
    };

    const handleEditClick = (expertiseId) => {
        navigate(`/edit-expertise/${expertiseId}`);
    };

    const resetPageToFirst = () => {
        setCurrentPage(1);
    };

    const getStatusSvg = (status) => {
        switch (status) {
            case "start":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill="green" /></svg>;
            case "delete":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><line x1="4" y1="4" x2="16" y2="16" stroke="red" strokeWidth="3" /><line x1="16" y1="4" x2="4" y2="16" stroke="red" strokeWidth="3" /></svg>;
            case "notstart":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill="grey" /></svg>;
            case "complete":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><polyline points="4,11 8,15 16,6" stroke="green" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round"/></svg>;
            default:
                return null;
        }
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleChangePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Container fluid>
            <h2 className="expertise-title">Suivi du dossier</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            <InputGroup>
                                <Form.Select
                                    value={searchStatus}
                                    onChange={e => {
                                        setSearchStatus(e.target.value);
                                        resetPageToFirst();
                                    }}
                                >
                                    <option value="start">Commencé</option> {/* Par défaut sélectionné */}
                                    <option value="delete">Annulé</option>
                                    <option value="notstart">Pas commencé</option>
                                    <option value="complete">Terminé</option>
                                </Form.Select>
                            </InputGroup>
                        </th>
                        <th>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder="Rechercher par titre..."
                                    value={searchTitle}
                                    onChange={e => {
                                        setSearchTitle(e.target.value);
                                        resetPageToFirst();
                                    }}
                                />
                            </InputGroup>
                        </th>
                        <th>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder="Rechercher par titulaire..."
                                    value={searchReceiver}
                                    onChange={e => {
                                        setSearchReceiver(e.target.value);
                                        resetPageToFirst();
                                    }}
                                />
                            </InputGroup>
                        </th>
                        <th>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder="Rechercher par date..."
                                    value={searchDueDate}
                                    onChange={e => {
                                        setSearchDueDate(e.target.value);
                                        resetPageToFirst();
                                    }}
                                />
                            </InputGroup>
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map(task => (
                        <tr key={task.id}>
                            <td>{getStatusSvg(task.status)}</td>
                            <td>{task.title}</td>
                            <td>{task.receiver}</td>
                            <td>{formatDate(task.review_at)}</td>
                            <td>
                                <Button variant="primary" onClick={() => handleEditClick(task.expertise)}>Gestion</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Row>
                <Col>
                    <Pagination>
                        {[...Array(totalPages)].map((_, i) => (
                            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handleChangePage(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            </Row>
        </Container>
    );
}

export default TaskList;
