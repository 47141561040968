import axios from 'axios';

const removeTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
};
const axiosInstance = axios.create({
    baseURL: 'https://expomis.com/api/',
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(config => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            try {
                const response = await axios.post('https://expomis.com/api/token/refresh/', {
                    refresh: refreshToken
                });

                if (response.status === 200) {
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

                    // Réessayez la requête avec le nouveau jeton d'accès
                    return axiosInstance(originalRequest);
                }
            } catch (refreshError) {
                console.error("Unable to refresh token:", refreshError);
                // En cas d'erreur lors du rafraîchissement du jeton, déconnectez l'utilisateur
                removeTokens();
                window.location.href = '/login'; // Redirigez l'utilisateur vers la page de connexion
                return Promise.reject(refreshError);
            }
        } else {
            // Si le jeton de rafraîchissement est manquant
            removeTokens();
            window.location.href = '/login'; // Redirigez l'utilisateur vers la page de connexion
        }
    }

    if (error.response.status === 401 || error.response.data.code === "token_not_valid") {
        removeTokens();
        window.location.href = '/login'; // Redirigez l'utilisateur vers la page de connexion
    }

    if (error.response.status === 400) {
        removeTokens();
        window.location.href = '/login'; // Redirigez l'utilisateur vers la page de connexion
    }

    return Promise.reject(error);
});

export default axiosInstance;
