import { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';

function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const submit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            setMessage('Veuillez compléter le CAPTCHA!');
            return;
        }

        try {
            const { data } = await axiosInstance.post('signup/', {
                email: email,
                password: password,
                recaptcha: recaptchaToken,
            });
            setMessage(data.message);
        } catch (error) {
            console.error('Signup error:', error);
            if (error.response && error.response.data) {
                setMessage(error.response.data.error || 'Failed to register. Please try again.');
            } else {
                setMessage('Failed to register. Please try again.');
            }
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    useEffect(() => {
        const handleReCAPTCHAV3 = async () => {
            const token = await window.grecaptcha.execute('6Lfft_0pAAAAAESWF337nK-0GOR5XIMhNDoo1Mh5', { action: 'submit' });
            handleRecaptchaChange(token);
        };

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=6Lfft_0pAAAAAESWF337nK-0GOR5XIMhNDoo1Mh5`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.grecaptcha.ready(() => {
                handleReCAPTCHAV3();
            });
        };
    }, []);

    return (
        <div className="Auth-form-container d-flex justify-content-center align-items-center vh-100">
            <div className="Auth-form card shadow">
                <div className="card-body">
                    <h3 className="Auth-form-title text-center">Inscription</h3>
                    {message && <div className="alert alert-info">{message}</div>}
                    <form onSubmit={submit}>
                        <div className="form-group mt-3">
                            <label htmlFor="email">Email</label>
                            <input
                                id="email"
                                className="form-control mt-1"
                                placeholder="Entrer votre email"
                                name="email"
                                type="email"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="password">Mot de passe</label>
                            <input
                                id="password"
                                className="form-control mt-1"
                                placeholder="Entrer votre mot de passe"
                                name="password"
                                type="password"
                                value={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-4">
                            <button type="submit" className="btn btn-primary">
                                S'inscrire
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signup;
