import React, { useState } from 'react';
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import CreateExpertise from './createExpertise';

function ModalCreateExpertise({ onExpertiseCreated, onEditClick }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Button variant="primary" onClick={handleShowModal}>
            Ajouter une Expertise
          </Button>

          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Créer une nouvelle expertise</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Transmettre onEditClick à CreateExpertise */}
              <CreateExpertise onExpertiseCreated={onExpertiseCreated} onEditClick={onEditClick} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalCreateExpertise;
