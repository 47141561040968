import { useParams } from 'react-router-dom'
import Expmanage from '../../components/Expmanage'

function Expertise(){

    return (
        <div style={{ paddingTop: '56px'}}>
            <h1 className="expertise-title">
                Liste des expertises
            </h1>
                <Expmanage />
        </div>
    )
}

export default Expertise