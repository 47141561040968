import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axiosInstance from '../../axiosInstance'; 

function CreateExpertise(props) {
    const [newExpertise, setNewExpertise] = useState({
        name: '',
        reference: '',
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [createdExpertiseId, setCreatedExpertiseId] = useState(null);

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setNewExpertise(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await axiosInstance.post(`/expertise/`, newExpertise);
            console.log(response.data); // Affichera l'objet Expertise créé avec son ID
            setSuccessMessage("L'expertise a été créée avec succès!");

            const createdExpertise = response.data;
            setCreatedExpertiseId(createdExpertise.id); // Stockez l'ID de l'expertise créée

            // Appeler la fonction de rappel pour signaler la création de l'expertise
            props.onExpertiseCreated(createdExpertise); 
        } catch (error) {
            console.error("Erreur lors de la création de l'expertise", error);
            if (error.response) {
                console.log(error.response.data); // Data returned in response to the error
                console.log(error.response.status); // The status code of the response
                console.log(error.response.headers); // The headers of the response
            }
        }
    };
    

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2>Ajouter une Expertise</h2>
                    <Form onSubmit={handleSubmit}>
                        {/* Fields for the expertise */}
                        <Form.Group className="mb-3">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom de l'expertise"
                                name="name"
                                value={newExpertise.name}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Référence</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Référence de l'expertise"
                                name="reference"
                                value={newExpertise.reference}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">Soumettre</Button>
                    </Form>
                    {successMessage && (
                        <Alert variant="success">
                            {successMessage}
                            {/* Bouton Modifier */}
                            {createdExpertiseId && (
                                <Button 
                                    variant="link" 
                                    onClick={() => props.onEditClick(createdExpertiseId)} 
                                    style={{ marginLeft: '10px' }}
                                >
                                    Modifier
                                </Button>
                            )}
                        </Alert>
                    )}

                </Col>
            </Row>
        </Container>
    );
}

export default CreateExpertise;
