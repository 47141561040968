import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ListFile from './listFile';

function Files() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListFile />
        </Col>
      </Row>
    </Container>
  );
}

export default Files;
