import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Container, Table } from 'react-bootstrap';
import axiosInstance from '../../axiosInstance'; 
import ReactQuill from 'react-quill'; // import the editor
import 'react-quill/dist/quill.snow.css'; // import the styles
import InfoPopover from './listvariable';

function PDFFiles() {
    const [files, setFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingFile, setEditingFile] = useState(null); // State to track the currently editing PDF
    const [newContent, setNewContent] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [contentError, setContentError] = useState('');

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = () => {
        axiosInstance.get(`/templates/`)
            .then(response => {
                setFiles(response.data.results || response.data);
            })
            .catch(error => {
                console.error('Error fetching files:', error);
            });
    };

    const handleContentChange = (content) => {
        setNewContent(content);
        if (!content) setContentError('Content is required.');
        else setContentError('');
    };

    const handleContentUpload = () => {
        if (!newTitle || !newContent) {
            if (!newTitle) setTitleError('Title is required.');
            if (!newContent) setContentError('Content is required.');
            return;
        }

        const formData = new FormData();
        formData.append('content', newContent);
        formData.append('title', newTitle);

        const url = editingFile ? `/templates/${editingFile.id}/` : `/templates/`;
        const method = editingFile ? 'patch' : 'post';

        console.log(`Sending ${method.toUpperCase()} request to ${url}`);
        console.log('FormData:', formData.get('title'), formData.get('content'));

        axiosInstance({ url, method, data: formData })
            .then((response) => {
                console.log('Response:', response.data);
                fetchFiles();
                handleCloseModal();
            })
            .catch(error => {
                console.error('Error uploading content:', error);
            });
    };

    const handleOpenModal = (file = null) => {
        setShowModal(true);
        if (file) {
            setEditingFile(file);
            setNewTitle(file.title);
            setNewContent(file.content || '');
        } else {
            setEditingFile(null);
            setNewContent('');
            setNewTitle('');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingFile(null);
        setNewContent('');
        setNewTitle('');
        setTitleError('');
        setContentError('');
    };

    const handleDeleteFile = (fileId) => {
        axiosInstance.delete(`/templates/${fileId}/`)
            .then(() => {
                fetchFiles();
            })
            .catch(error => {
                console.error('Error deleting file:', error);
            });
    };

    return (
        <Container fluid>
            <h2>Template sous forme de texte</h2>
            <Button variant="primary" onClick={() => handleOpenModal()}>Upload New PDF</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file.id}>
                            <td>{file.title}</td>
                            <td>
                                <Button variant="danger" onClick={() => handleDeleteFile(file.id)}>
                                    Delete
                                </Button>
                                <Button variant="info" onClick={() => handleOpenModal(file)} style={{ marginLeft: 10 }}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal dialogClassName="modal-70w" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingFile ? 'Edit PDF' : 'Upload New PDF'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Titre du document</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                value={newTitle}
                                onChange={(e) => setNewTitle(e.target.value)}
                                isInvalid={!!titleError}
                            />
                            <Form.Control.Feedback type="invalid">{titleError}</Form.Control.Feedback>
                            <Form.Label>Model de texte</Form.Label>
                            <InfoPopover/>
                            <ReactQuill
                                value={newContent}
                                onChange={handleContentChange}
                                placeholder="Entrez le contenu"
                                theme="snow"
                                style={{ height: '600px' }}
                            />
                            {contentError && <div style={{ color: 'red' }}>{contentError}</div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <br></br>
                <br></br>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={handleContentUpload}>{editingFile ? 'Save Changes' : 'Upload'}</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default PDFFiles;
