import React, { useState, useCallback } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import TaskView from './taskView';

function Task() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <TaskView />
        </Col>
      </Row>
    </Container>
  );
}

export default Task;
