import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, Row, Col, Button, Alert, Modal, Form, Table } from 'react-bootstrap';
import ReactQuill from 'react-quill'; // import the editor
import 'react-quill/dist/quill.snow.css'; // import the styles

function TaskView() {
    const { expertiseId } = useParams();
    const [tasks, setTasks] = useState([]);
    const initialTaskState = {
        title: '',
        title_bis: '',
        comment: '',
        review_at: '',
        sender: '',
        receiver: '',
        copy_receiver: '',
        hierarchy: '',
        public: false,  // Assuming this should be boolean based on the model default
        status: 'notstart',  // Default status
    };
    const [currentTask, setCurrentTask] = useState({ ...initialTaskState });
    const [showAddModal, setShowAddModal] = useState(false);
    const [error, setError] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [pdfFiles, setPdfFiles] = useState([]);


    const ReactQuillModules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
        ],
    };
    
    const ReactQuillFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent'
    ];
    

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
    };

    useEffect(() => {
        fetchTasks();
        fetchPDFFiles(); 
    }, [expertiseId]);

    // Fonction pour charger les suivis
    const fetchTasks = async () => {
        try {
            const response = await axiosInstance.get(`/tasks_model/`);
            const data = response.data;
            setTasks(data.results || data);
            setError('');  // Reset l'erreur si le chargement réussit
        } catch (err) {
            setError('Erreur lors de la récupération des suivis');
        }
    };

    const fetchPDFFiles = async () => {
        try {
            const response = await axiosInstance.get(`/templates/`);
            setPdfFiles(response.data.results || response.data); // Assurez-vous que le format de la réponse correspond
        } catch (error) {
            console.error('Erreur lors de la récupération des PDFs:', error);
        }
    };

    
    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => {
        setShowAddModal(false);
        setCurrentTask({ ...initialTaskState });
    };

    const handleShowEditModal = (task) => {
        const editedTask = {
            title: task.title || '',
            title_bis: task.title_bis || '',
            comment: task.comment || '',
            review_at: task.review_at ? task.review_at.split('T')[0] : '',
            sender: task.sender || '',
            receiver: task.receiver || '',
            copy_receiver: task.copy_receiver || '',
            hierarchy: task.hierarchy || '',
            public: task.public !== undefined ? task.public : false,  // Ensure boolean fields are not set to undefined
            status: task.status || 'notstart',
        };
    
        setCurrentTask(editedTask);
        setEditingTaskId(task.id);
        setShowEditModal(true);
    };
    
    

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setCurrentTask({ ...initialTaskState });
        setEditingTaskId(null);
    };
    
    

    const handleChange = (e) => {
        let name, value;
    
        // Check if event has a target property, typical for standard inputs
        if (e.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            // For ReactQuill, assume 'comment' as name since it's the only Quill editor
            name = 'comment';
            value = e;
        }
    
        // Update the state with the new value
        setCurrentTask(prevTask => ({
            ...prevTask,
            [name]: value
        }));
    };
    

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        const formattedData = {
            ...currentTask,
            review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            expertise: expertiseId,
        };
        try {
            const response = await axiosInstance.post(`/tasks_model/`, formattedData );
            setTasks([...tasks, response.data]);  // Update the list with the new task
            handleCloseAddModal();
        } catch (error) {
            setError('Erreur lors de l\'ajout de la suivi');
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedData = {
                ...currentTask,
                review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            };
            const response = await axiosInstance.put(`/tasks_model/${editingTaskId}/`, formattedData);
            const updatedTasks = tasks.map(t => t.id === editingTaskId ? response.data : t);
            setTasks(updatedTasks);
            handleCloseEditModal();
        } catch (error) {
            setError('Erreur lors de la modification de la suivi');
        }
    };
    
    const handlePDFSelect = async (event) => {
        const selectedPDFId = event.target.value;
        if (selectedPDFId) {
            try {
                const response = await axiosInstance.get(`/templates/${selectedPDFId}/`);
                const selectedPDF = response.data;
                setCurrentTask(prevTask => ({
                    ...prevTask,
                    comment: selectedPDF.content  // Assurez-vous que 'content' est le bon champ
                }));
            } catch (error) {
                console.error('Erreur lors de la récupération du contenu du PDF:', error);
            }
        }
    };
    
    const getStatusSvg = (status) => {
        switch (status) {
            case "start":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill="green" /></svg>;
            case "delete":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><line x1="4" y1="4" x2="16" y2="16" stroke="red" strokeWidth="3" /><line x1="16" y1="4" x2="4" y2="16" stroke="red" strokeWidth="3" /></svg>;
            case "notstart":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill="grey" /></svg>;
            case "complete":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><polyline points="4,11 8,15 16,6" stroke="green" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round"/></svg>;
            default:
                return null;
        }
    };
    return (
        <Container fluid>
            <h2 className="expertise-title">Liste des Suivis</h2>
            <Button variant="primary" onClick={handleShowAddModal}>Ajouter une suivi</Button>

            {error && <Alert variant="danger">{error}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Titre</th>
                        <th>Titulaire</th>
                        <th>Echéance</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map(task => (
                        <tr key={task.id}>
                            <td>{getStatusSvg(task.status)}</td>
                            <td>{task.title}</td>
                            <td>{task.receiver}</td>
                            <td>{formatDate(task.review_at)}</td>
                            <td>
                            <Button variant="primary" size="sm" onClick={() => handleShowEditModal(task)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                                    </svg>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal dialogClassName="modal-70w"  show={showAddModal} onHide={handleCloseAddModal}>
            <Form onSubmit={handleAddSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter une nouvelle suivi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="formTaskTitle">
                                <Form.Label>Titre</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={currentTask.title}
                                    onChange={handleChange}
                                    placeholder="Entrez le titre de la suivi"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={currentTask.status}
                                onChange={handleChange}
                            >
                                <option value="start">Commencée</option>
                                <option value="delete">Annulée</option>
                                <option value="notstart">Pas commencée</option>
                                <option value="complete">Achevée</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskPublic">
                            <Form.Label>Public</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="public"
                                checked={currentTask.public}
                                onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                label="Est-ce que la suivi est publique ?"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskReviewAt">
                            <Form.Label>Date de révision</Form.Label>
                            <Form.Control
                                type="date"
                                name="review_at"
                                value={currentTask.review_at}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskHierarchy">
                            <Form.Label>Hiérarchie</Form.Label>
                            <Form.Control
                                type="text"
                                name="hierarchy"
                                value={currentTask.hierarchy}
                                onChange={handleChange}
                                placeholder="Niveau de hiérarchie de la suivi"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formTaskSender">
                        <Form.Label>Expéditeur</Form.Label>
                        <Form.Control
                            type="text"
                            name="sender"
                            value={currentTask.sender}
                            onChange={handleChange}
                            placeholder="Email de l'expéditeur"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskReceiver">
                        <Form.Label>Destinataire</Form.Label>
                        <Form.Control
                            type="text"
                            name="receiver"
                            value={currentTask.receiver}
                            onChange={handleChange}
                            placeholder="Email du destinataire"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskCopyReceiver">
                        <Form.Label>Copie à</Form.Label>
                        <Form.Control
                            type="text"
                            name="copy_receiver"
                            value={currentTask.copy_receiver}
                            onChange={handleChange}
                            placeholder="Email des autres destinataires"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskTitleBis">
                        <Form.Label>Sujet</Form.Label>
                        <Form.Control
                            type="text"
                            name="title_bis"
                            value={currentTask.title_bis}
                            onChange={handleChange}
                            placeholder="Titre du courriel"
                        />
                    </Form.Group>
                    <Form.Group controlId="formPDFSelect">
                        <Form.Label>Sélectionner un PDF</Form.Label>
                        <Form.Control as="select" onChange={handlePDFSelect}>
                            <option value="">Choisir...</option>
                            {pdfFiles.map(pdf => (
                                <option key={pdf.id} value={pdf.id}>{pdf.title}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTaskComment">
                        <Form.Label>Contenu</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={currentTask.comment}
                            onChange={handleChange} // Directly pass the new content
                            placeholder="Enter comment here..."
                            modules={ReactQuillModules}
                            formats={ReactQuillFormats}
                            style={{ height: '420px' }} // Adjust the height as needed
                        />
                    </Form.Group>
            </Modal.Body>
            <br />
            <br />
            <br />
            <br />
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddModal}>
                    Fermer
                    </Button>
                    <Button variant="primary" type="submit">
                        Sauvegarder
                    </Button>
            </Modal.Footer>
            </Form>
        </Modal>
        <Modal dialogClassName="modal-70w" show={showEditModal} onHide={handleCloseEditModal}>
        <Form onSubmit={handleEditSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Modifier la suivi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                        <Col>
                            <Form.Group controlId="formTaskTitle">
                                <Form.Label>Titre</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={currentTask.title}
                                    onChange={handleChange}
                                    placeholder="Entrez le titre de la suivi"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={currentTask.status}
                                onChange={handleChange}
                            >
                                <option value="start">Commencée</option>
                                <option value="delete">Annulée</option>
                                <option value="notstart">Pas commencée</option>
                                <option value="complete">Achevée</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskPublic">
                            <Form.Label>Public</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="public"
                                checked={currentTask.public}
                                onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                label="Est-ce que la suivi est publique ?"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskReviewAt">
                            <Form.Label>Date de révision</Form.Label>
                            <Form.Control
                                type="date"
                                name="review_at"
                                value={currentTask.review_at}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskHierarchy">
                            <Form.Label>Hiérarchie</Form.Label>
                            <Form.Control
                                type="text"
                                name="hierarchy"
                                value={currentTask.hierarchy}
                                onChange={handleChange}
                                placeholder="Niveau de hiérarchie de la suivi"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formTaskSender">
                        <Form.Label>Expéditeur</Form.Label>
                        <Form.Control
                            type="text"
                            name="sender"
                            value={currentTask.sender}
                            onChange={handleChange}
                            placeholder="Email de l'expéditeur"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskReceiver">
                        <Form.Label>Destinataire</Form.Label>
                        <Form.Control
                            type="text"
                            name="receiver"
                            value={currentTask.receiver}
                            onChange={handleChange}
                            placeholder="Email du destinataire"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskCopyReceiver">
                        <Form.Label>Copie à</Form.Label>
                        <Form.Control
                            type="text"
                            name="copy_receiver"
                            value={currentTask.copy_receiver}
                            onChange={handleChange}
                            placeholder="Email des autres destinataires"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskTitleBis">
                        <Form.Label>Sujet</Form.Label>
                        <Form.Control
                            type="text"
                            name="title_bis"
                            value={currentTask.title_bis}
                            onChange={handleChange}
                            placeholder="Titre du courriel"
                        />
                    </Form.Group>
                    <Form.Group controlId="formPDFSelect">
                        <Form.Label>Sélectionner un PDF</Form.Label>
                        <Form.Control as="select" onChange={handlePDFSelect}>
                            <option value="">Choisir...</option>
                            {pdfFiles.map(pdf => (
                                <option key={pdf.id} value={pdf.id}>{pdf.title}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTaskComment">
                        <Form.Label>Contenu</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={currentTask.comment}
                            onChange={handleChange} // Directly pass the new content
                            placeholder="Enter comment here..."
                            modules={ReactQuillModules}
                            formats={ReactQuillFormats}
                            style={{ height: '420px' }} // Adjust the height as needed
                        />
                    </Form.Group>

            </Modal.Body>
            <br />
            <br />
            <br />
            <br />
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditModal}>Fermer</Button>
                <Button variant="primary" type="submit">Sauvegarder les changements</Button>
            </Modal.Footer>
            </Form>
        </Modal>

        </Container>
    );
}

export default TaskView;
