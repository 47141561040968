import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, ListGroup, Badge, Button, Alert, Modal, Form, ModalBody } from 'react-bootstrap';


function ReportView() {
    const { expertiseId } = useParams();
    const [reportFields, setReportFields] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        type_field: 'description', 
    });
    const [selectedField, setSelectedField] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const fetchReportFields = async () => {
        try {
            const response = await axiosInstance.get(`/reportfieldtexts/?expertise_id=${expertiseId}`);
            const data = response.data;
            setReportFields(data.results || data);
        } catch (err) {
            setError('Erreur lors du chargement des champs de rapport');
            console.error(err);
        }
    };
    
    useEffect(() => {
        fetchReportFields();
    }, [expertiseId]);

    const handleEditClick = (field) => {
        setSelectedField(field); // Stocker les données du champ sélectionné
        setFormData({ // Pré-remplir le formulaire avec les données du champ sélectionné
            title: field.title,
            description: field.description,
            at_date: field.at_date ? new Date(field.at_date).toISOString().split('T')[0] : null,
            check_box: !!field.check_box,
            type_field:field.type_field
        });
        setShowEditModal(true); // Afficher le modal de modification
    };
    

    const handleAddReportField = async (e) => {
        e.preventDefault();
        const payload = {
            ...formData,
            expertise: expertiseId,
        };
        console.log(payload)
        try {
            await axiosInstance.post(`/reportfieldtexts/?expertise_id=${expertiseId}`, payload);
            setShowModal(false); // Fermer le modal après la soumission
            // Réinitialiser le formulaire (optionnel)
            setFormData({ title: '', type_field: 'description', position: 99 });
            // Rafraîchir la liste des champs de rapport
            fetchReportFields();
        } catch (err) {
            console.error('Erreur lors de l\'ajout du champ de rapport', err);
            // Gérer l'erreur ici (par exemple, définir un message d'erreur dans l'état `error`)
        }
    };

    const handleUpdateReportField = async (e) => {
        e.preventDefault();
        // Assurez-vous d'inclure l'ID du champ dans l'URL si nécessaire
        const url = `/reportfieldtexts/${selectedField.id}/`;
        try {
            await axiosInstance.put(url, {...formData, expertise: expertiseId});
            setShowEditModal(false); // Fermer le modal après la soumission
            fetchReportFields(); // Rafraîchir la liste des champs de rapport
        } catch (err) {
            console.error('Erreur lors de la mise à jour du champ de rapport', err);
        }
    };
    
    
    return (
        <Container fluid>
            <h2 className="expertise-title">Rapport</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button variant="outline-primary" onClick={() => setShowModal(true)}>Ajouter un Champ</Button>
            {reportFields.map((field) => {
            const renderField = () => {
                if (field.type_field === 'check_box') {
                return (
                    <ListGroup key={`report_${field.id}`} >
                        <ListGroup.Item>
                            <p>{field.check_box ? "Oui" : "Non"} - {field.title}</p>
                            <Button variant="primary" size="sm" onClick={() => handleEditClick(field)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                            </svg>
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                );
                } else if (field.type_field === 'at_date') {
                return (
                    <ListGroup key={`report_${field.id}`} >
                        <ListGroup.Item>
                            <p>{field.title}</p>
                            <p>{field.at_date ? new Date(field.at_date).toLocaleDateString() : "Aucune"}</p>
                            <Button variant="primary" size="sm" onClick={() => handleEditClick(field)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                            </svg>
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                );
                } else {
                return (
                    <ListGroup key={`report_${field.id}`} >
                        <ListGroup.Item>
                            <p>{field.title}</p>
                            <p>{field.description}</p>
                            <Button variant="primary" size="sm" onClick={() => handleEditClick(field)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                            </svg>
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                );
                }
            };

            return renderField();
            })}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Form onSubmit={handleAddReportField}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ajouter un Nouveau Champ de Rapport</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Titre</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData.title}
                                onChange={e => setFormData({...formData, title: e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Type de Champ</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={formData.type_field}
                                onChange={e => setFormData({...formData, type_field: e.target.value})}
                            >
                                <option value="check_box">Check Box</option>
                                <option value="description">Description</option>
                                <option value="at_date">Date</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Position</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={formData.position}
                                onChange={e => setFormData({...formData, position: e.target.value})}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Fermer</Button>
                        <Button variant="primary" type="submit">Sauvegarder</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal dialogClassName="modal-70w" show={showModal || showEditModal} onHide={() => { setShowModal(false); setShowEditModal(false); }}>
                <Form onSubmit={selectedField ? handleUpdateReportField : handleAddReportField}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedField ? 'Modifier' : 'Ajouter un Nouveau'} Champ de Rapport</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Titre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le titre"
                                value={formData.title}
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </Form.Group>
                        {formData.type_field === 'description' && (
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Entrez la description"
                                    value={formData.description || ''}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                        )}
                        {formData.type_field === 'at_date' && (
                            <Form.Group>
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={formData.at_date || ''}
                                    onChange={(e) => setFormData({ ...formData, at_date: e.target.value })}
                                />
                            </Form.Group>
                        )}
                        {formData.type_field === 'check_box' && (
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Cocher"
                                    checked={!!formData.check_box}
                                    onChange={(e) => setFormData({ ...formData, check_box: e.target.checked })}
                                />
                            </Form.Group>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowModal(false); setShowEditModal(false); }}>Fermer</Button>
                        <Button variant="primary" type="submit">{selectedField ? 'Mettre à jour' : 'Sauvegarder'}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </Container>
    );
    
}

export default ReportView;
