import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; 
import { Container, Button, Alert, Modal, Form, Table } from 'react-bootstrap';


function TaskListModelView() {
    const [tasksList, setTasksList] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [currentTaskList, setCurrentTaskList] = useState({ id: null, title: '', tasks: [], actif: true });
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        fetchTaskLists();
        fetchAllTasks();
    }, []);
    useEffect(() => {
        console.log('Selected tasks updated:', selectedTasks);
      }, [selectedTasks]);  // Cette ligne indique à React de réexécuter ce bloc chaque fois que `selectedTasks` change.
      

    const fetchTaskLists = async () => {
        try {
            const response = await axiosInstance.get(`/tasks_list_model/`);
            setTasksList(response.data.results || response.data); // Assuming the structure contains either a results array or directly the data
            console.log('Task Lists fetched:', response.data);
        } catch (error) {
            setError('Erreur lors de la récupération des listes de tâches');
        }
    };
    

    const fetchAllTasks = async () => {
        try {
            const response = await axiosInstance.get(`/tasks_model/`);
            setAllTasks(response.data.results || response.data);
            console.log('All tasks:', response.data);
        } catch (error) {
            setError('Erreur lors de la récupération des modèles de tâches');
        }
    };
    
    const handleShowModal = (taskList = null) => {
        setError('');
        setShowModal(true);
      
        if (taskList) {
            console.log('Task objects in the list:', taskList.tasks);
            const taskIds = taskList.tasks.map(task => {
              console.log('Task object:', task); // Ajoutez ceci pour voir la structure de chaque tâche
              return task;
            });
            setSelectedTasks(taskIds);
            console.log('Tasks IDs:', taskIds);
            setCurrentTaskList(taskList);
            setIsEditing(true);
          } else {
          setCurrentTaskList({ id: null, title: '', tasks: [], actif: true });
          setSelectedTasks([]);
          setIsEditing(false);
        }
      };
      
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCheckboxChange = (taskId) => {
        if (taskId !== undefined) {
            setSelectedTasks(prev => {
                const newSelectedTasks = prev.includes(taskId) ? prev.filter(id => id !== taskId) : [...prev, taskId];
                console.log('Checkbox change, updated tasks selections:', newSelectedTasks);
                return newSelectedTasks;
            });
        } else {
            console.log('Attempted to toggle an undefined task ID');
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            title: currentTaskList.title,
            tasks: selectedTasks,
            actif: currentTaskList.actif
        };
    
        console.log('Data being sent to API:', data);
        const url = `/tasks_list_model/${isEditing ? `${currentTaskList.id}/` : ''}`;
        const method = isEditing ? 'put' : 'post';
    
        try {
            const response = await axiosInstance({
                method: method,
                url: url,
                data: data
            });
            if ([200, 201].includes(response.status)) {
                fetchTaskLists();
                handleCloseModal();
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            console.error('API call failed:', error);
            setError(`Error while ${isEditing ? 'updating' : 'adding'} the task list: ${error.message}`);
        }
    };
    

    const handleDeleteTaskList = async (id) => {
        try {
            await axiosInstance.delete(`/tasks_list_model/${id}/`);
            setTasksList(tasksList.filter(taskList => taskList.id !== id));
        } catch (error) {
            setError('Erreur lors de la suppression de la liste de tâches');
        }
    };

    return (
        <Container fluid>
            <h2>Liste des modèles de tâches</h2>
            <Button variant="primary" onClick={() => handleShowModal()}>Ajouter une liste de tâches</Button>
            {error && <Alert variant="danger">{error}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Titre</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tasksList.map(taskList => (
                        <tr key={taskList.id}>
                            <td>{taskList.title}</td>
                            <td>
                                <Button variant="primary" size="sm" onClick={() => handleShowModal(taskList)}>Modifier</Button>{' '}
                                <Button variant="danger" size="sm" onClick={() => handleDeleteTaskList(taskList.id)}>Supprimer</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal dialogClassName="modal-70w" show={showModal} onHide={handleCloseModal}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? 'Modifier la liste de tâches' : 'Ajouter une nouvelle liste de tâches'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Titre</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={currentTaskList.title}
                                onChange={e => setCurrentTaskList({ ...currentTaskList, title: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check 
                                type="checkbox" 
                                label="Actif" 
                                checked={currentTaskList.actif}
                                onChange={e => setCurrentTaskList({ ...currentTaskList, actif: e.target.checked })}
                            />
                        </Form.Group>
                        {allTasks.map(task => (
                            <Form.Check
                                key={task.id}
                                type="checkbox"
                                label={task.title}
                                checked={selectedTasks.includes(task.id)}
                                onChange={() => handleCheckboxChange(task.id)}
                            />
                        ))}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Fermer</Button>
                        <Button variant="primary" type="submit">Sauvegarder</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>
    );
}

export default TaskListModelView;
