import { useEffect } from "react";
import axiosInstance from '../../axiosInstance'; 

function Logout({ setIsLoggedIn }) {
  const refreshToken = localStorage.getItem('refresh_token');
  if (refreshToken) {
    axiosInstance.post('/logout/', { refresh_token: refreshToken }, { headers: { 'Content-Type': 'application/json' }})
          .then(response => {
              console.log('Logged out successfully');
              setIsLoggedIn(false);
              localStorage.clear(); // Clears all localStorage items
              window.location.href = '/login'; // Redirect to login
          })
          .catch(error => {
              console.error('Logout failed', error);
          });
  } else {
      console.log('No refresh token found');
  }
}
export default Logout

