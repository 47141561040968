import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ListContact from './listContact';

function Contacts() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListContact />
        </Col>
      </Row>
    </Container>
  );
}

export default Contacts;
