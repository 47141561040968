import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ListExpertise from './listExpertise';

function Expmanage() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListExpertise />
        </Col>
      </Row>
    </Container>
  );
}

export default Expmanage;
