import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axiosInstance from '../../axiosInstance'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditExpertise() {
    const { expertiseId } = useParams(); 
    const [expertise, setExpertise] = useState({
        name: '',
        reference: '',
        statut: '',
    });
    const [successMessage, setSuccessMessage] = useState(''); // Ajout de l'état pour le message de succès

    useEffect(() => {
        axiosInstance.get(`/expertise/${expertiseId}`)
            .then(response => {
                const data = response.data;
                setExpertise({
                    name: data.name || '',
                    reference: data.reference || '',
                    statut: data.statut || '',
                });
            })
            .catch(error => {
                console.error("Erreur lors du chargement de l'expertise", error);
            });
    }, [expertiseId]);

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setExpertise(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formattedData = {
            ...expertise,
        };

        try {
            const response = await axiosInstance.put(`/expertise/${expertiseId}/`, formattedData);
            // Afficher un message de succès
            toast.success('Les champs ont bien été modifiés.');
            // Réinitialiser l'alerte après un certain temps si nécessaire
            setTimeout(() => setSuccessMessage(''), 3000); // Masquer l'alerte après 3 secondes
        } catch (error) {
            console.error("Erreur lors de la modification de l'expertise", error);
            // Gérer l'erreur ici
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        }
    };

    return (
        <Container fluid>
            <ToastContainer position="top-right" autoClose={3000} />
            <Row>
                <Col>
                    <h2 className="expertise-title">Modifier une Expertise</h2>
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        {/* Champs pour l'expertise */}
                        <Form.Group className="mb-3">
                            <Form.Label>Nom du dossier</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom de l'expertise"
                                name="name"
                                value={expertise.name}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Votre référence</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Référence de l'expertise"
                                name="reference"
                                value={expertise.reference}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Statut</Form.Label>
                            <Form.Control
                                as="select"
                                name="statut"
                                value={expertise.statut}
                                onChange={handleFormChange}
                            >
                                <option value="">Sélectionnez un statut</option>
                                <option value="Préparation">Préparation</option>
                                <option value="Contradictoire">Contradictoire</option>
                                <option value="Clôture">Clôture</option>
                                <option value="Annuler">Annuler</option>
                            </Form.Control>
                        </Form.Group>
                        <Button variant="outline-primary" type="submit">Soumettre</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default EditExpertise;
