import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ListContent from './listContent';

function Contents() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListContent />
        </Col>
      </Row>
    </Container>
  );
}

export default Contents;
