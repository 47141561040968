// Import the react JS packages 
import axiosInstance from '../../axiosInstance'; 
import {useState} from "react";


function Login({ setIsLoggedIn }) {
     const [username, setUsername] = useState('');
     const [password, setPassword] = useState('');
     // Create the submit method.
     const submit = async e => {
      e.preventDefault();
      try {
          const { data } = await axiosInstance.post('token/', {
              username: username,
              password: password
          });
          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);
          setIsLoggedIn(true);
          window.location.href = '/';
      } catch (error) {
          console.error('Login error:', error);
      }
      };
    
    return(
      <div className="Auth-form-container d-flex justify-content-center align-items-center vh-100">
        <div className="Auth-form card shadow">
          <div className="card-body">
            <h3 className="Auth-form-title text-center">Connexion</h3>
            <form onSubmit={submit}>
              <div className="form-group mt-3">
                <label htmlFor="username">Email</label>
                <input
                  id="username"
                  className="form-control mt-1"
                  placeholder="Entrer votre email"
                  name="username"
                  type="text"
                  value={username}
                  required
                  onChange={e => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="password">Mot de passe</label>
                <input
                  id="password"
                  className="form-control mt-1"
                  placeholder="Entrer votre mot de passe"
                  name="password"
                  type="password"
                  value={password}
                  required
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="d-grid gap-2 mt-4">
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
     )
}

export default Login