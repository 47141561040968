import React, { useState, useCallback } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ReportView from './reportView';

function Report() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ReportView />
        </Col>
      </Row>
    </Container>
  );
}

export default Report;
