import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { useParams} from 'react-router-dom';

const ActivateAccount = () => {
    const { uidb64, token } = useParams();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axiosInstance.get(`/activate/${uidb64}/${token}/`);
                setMessage(response.data.message);
                // Redirection après un court délai pour laisser l'utilisateur lire le message
                ;
            } catch (error) {
                if (error.response && error.response.data) {
                    setMessage(error.response.data.error);
                } else {
                    setMessage('Une erreur s\'est produite. Veuillez réessayer.');
                }
            }
        };
        activateAccount();
    }, [uidb64, token]);

    return (
        <div className="activation-container">
            <h1>Activation du compte</h1>
            <p>{message}</p>
        </div>
    );
};

export default ActivateAccount;
